@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

.gradient-text {
    background: linear-gradient(90deg, #2B2D3E 0%, #69709A 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    line-height: 1.2; /* Adjust as needed */
    padding: 0.1em 0;  /* Add some padding to prevent cutoff */
  }
.gradient-text2 {
    background: linear-gradient(0deg, #4B5173 0%, #C4C6F3 70%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    line-height: 1.2; /* Adjust as needed */
    padding: 0.1em 0;  /* Add some padding to prevent cutoff */
  }


  .border-bottom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; /* The border thickness */
    background: linear-gradient(90deg, #00031C 0%, #CCD9FF 40%, #00031C 100%);
  }

  .active {
    border-color: #5981F5;
  }

  @media screen and (max-width: 768px) {  
    .active {
      color: #5981F5 !important;
      border-color: #E9E9EA !important;
    }
  }

  video {
    object-fit: cover !important;
  }



  .opacity-100 {
    opacity: 1;
    /* transform: translateY(0);
    transition: opacity 0.5s ease, transform 1s ease; */
  }
  
  
  .breadcramp > .active {
    color: #fff !important;
  }

  .shadow-xs {
    box-shadow: 0px 0px 3px 0px #1018283a;
  }


  .loader2 {
    display: flex;
    justify-content: space-between;
    width: 60px;
  }
  
  .loader2 div {
    width: 15px;
    height: 15px;
    background-color: #1744C7;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
    box-shadow: 0 0 15px rgba(52, 66, 219, 0.542);
  }
  
  .loader2 div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .loader2 div:nth-child(3) {
    animation-delay: -0.6s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }